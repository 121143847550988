@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.result-label {
  position: absolute;
  color: white;
  font-size: 16px;
  font-weight: 900;
  transform: translateX(-50%) translateY(-40px);
}

.modal-call {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: #fbfbfb;
  min-width: 200px;
  min-height: 100px;
  max-width: 100%;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.15);
  padding: 25px;
}